* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 120%;

}

h1 {
  font-family: 'IvyMode', sans-serif;
  font-weight: 300;

}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1rem;
  }
}