.nav-con {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: fixed;
    z-index: 9999;
    transition: all 0.2s ease-in-out;
    background: #ffff;
}

.nav-con-bg {
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.12);
    background: rgba(255, 255, 255, 0.9);

}

.logo {
    padding-top: 0.5rem;
    padding-left: 2rem;
    width: 150px;
}

.logo img {
    width: 100%;
}

.burger-menu {
    height: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    color: #ffff;
    background: linear-gradient(180deg, #ff3974 0%, #ff6b38 100%);
    border-radius: 50%;
    font-size: 1.5rem;
}




.menu {
    overflow: hidden;
    height: 100vh;
    background: rgba(255, 255, 255, 0.98);
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transition: ease-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    font-size: 3rem;

}

.menu a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10vh;
    text-align: center;
    text-decoration: none;
    font-family: 'IvyMode', sans-serif;
    color: black;
    font-weight: 300;

}

/* ------------- sliding menu ------------------ */
.hidden {
    width: 0;
    transition: ease-out 0.3s;
}

.visible {
    width: 100%;
    transition: ease-out 0.3s;

}

@media screen and (max-width: 1200px) {
    .menu {
        font-size: 2.5rem;
    }




}

@media screen and (max-width: 980px) {
    .menu {
        font-size: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .visible {
        width: 100%;
    }

    .menu {
        background: rgba(255, 255, 255, 0.98);
        justify-content: center;
    }

    .menu a {
        width: 70%;

    }

    .burger-menu {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .logo {

        padding-left: 1rem;
        width: 120px;
    }

    .burger-menu {
        margin-right: 1rem;
    }
}