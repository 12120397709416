.contact-con {
    width: 100%;
    padding-top: 20vh;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.contact-left {
    margin-left: 10%;
    list-style-type: none;
}

.contact-con h1 {
    font-size: 64px;
}

.contact-right {
    width: 40%;
    display: flex;
    overflow: hidden;
}

.contact-right img {
    width: 100%;
    object-fit: cover;
    position: relative;
    right: -2rem;
}

@media screen and (max-width: 1100px) {
    .contact-con {
        flex-direction: column;
    }

    .contact-right {
        width: 100%;
    }

    .contact-right img {
        width: 100%;
        height: 300px;
        right: 0;
    }

    .For-Producers {
        padding-top: 2rem;
    }
}

@media screen and (max-width: 980px) {
    .contact-con h1 {
        font-size: 48px;
    }


}

@media screen and (max-width: 480px) {
    .contact-con h1 {
        font-size: 30px;
    }

    .contact-con {
        flex-direction: column;
    }

    .contact-right {
        height: 200px;
    }

    .contact-right img {
        right: 0px;
    }
}