.contact-container {
    max-width: none;
    margin: 0 auto;

}

.contact-container-con {
    display: flex;
    justify-content: space-around;
}

.Toastify__toast div {
    font-family: 'AcuminProWide', sans-serif;

}

.contact-item-right img {
    margin-top: 100px;
    width: 100%;
    height: 70%;
    margin-top: 150px;
    object-fit: cover;
    position: relative;
}

.contact-item-right {
    align-items: center;
    text-align: left;
}

.contact-item-left {
    margin: 150px;
    margin-right: 40px;
}

.contact-content-text p {
    padding-top: 1.5rem;
}

.contact-content-text h1 {
    font-weight: 300;
    font-size: 4rem;
    font-style: normal;
}

.contact-content-text li {
    padding: 1rem 0;
    padding-left: 1rem;
    list-style-type: none;
}

form {
    width: 400px;
    padding-top: 3rem;
    align-items: left;
    display: flex;
    flex-direction: column;
}

input[type=text]::placeholder,
textarea::placeholder,
input[type=email]::placeholder {
    opacity: 0.4;
}

input[type=text],
input[type=email],
textarea {
    width: 400px;
    padding: 10px 10px;
    flex-direction: column;
    align-items: right;
    background: none;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-family: 'AcuminProWide', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

input[type=text]:hover {
    border: none;
    border-bottom: 1px solid rgba(75, 72, 72, 0.3);
}

textarea {
    height: 150px;
    padding: 10px 10px;
}

.submit-btn {
    font-size: 20px;
    font-weight: 200;
    width: 150px;
    height: 60px;
    margin-bottom: 3rem;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    padding: 0.7rem 1.5rem;
    text-decoration: none;
    color: black;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #FF3939, #FF8139);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    transition: 0.3s;
    margin-top: 1rem;
    border-radius: 50px;
}

.submit-btn:hover {
    box-shadow: none;
    color: white;
}



.contactUs {
    padding-top: 3rem;
    background: #F3F3F1;
}

.contactUs h1 {
    background: #F3F3F1;
}

.alert {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #000000;
    border-radius: 20px;
    width: 40%;
    background-color: rgba(255, 255, 255, 0.9);

}

.msg-error {
    font-family: 'AcuminProWide', sans-serif;
    padding-left: 1rem;
    color: #FF3939;
    transition: all ease-in 3s;
}



.alert-message {
    margin: 2rem auto 1.5rem auto;


}

.alert-message h1 {
    margin-bottom: 0;
}

.alert-message p {
    font-family: 'acumin-pro-wide', sans-serif;
    color: #000000;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
}

.alert-icon {
    display: inline-block;
    position: fixed;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #FFFFFF;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 45px;

}

.alert-icon.success {
    color: green;
}

.alert-icon.error {
    color: #f44336;
}

.alert-close {
    bottom: 0;
    padding: 0;
    height: 50px;
    color: #FFFFFF;
    border: none;
    background: none;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    border-radius: 0 0 20px 20px;
    width: 100%;
}

.alert-close.success {

    background-color: green;
}

.alert-close.error {

    background-color: #f44336;
}

@media screen and (max-width:1024px) {
    .contact-container {
        max-width: 1000px;
        margin: 0 auto;
    }

    .contact-container-con {
        flex-direction: column;

    }

    .contact-item-right img {
        margin: 0;
        margin-top: 1rem;
        position: relative;
        height: 300px;
        width: 100%;
    }

    form {
        width: 100%;
        align-items: center;
    }

    form.input_contact {
        padding-top: 1rem;
        max-width: 90%;
    }

    .contactUs h1 {
        text-align: center;
        padding: 0;
        padding-bottom: 1rem;
    }

    .contact-item-left {
        margin: 0 3rem;
        margin-left: 2rem;
        padding-left: 0;
    }

    input[type=text],
    textarea,
    input[type=email] {

        padding: 10px 10px;
        flex-direction: column;
        align-items: center;
        background: none;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    .For-Producers {
        padding-top: 2rem;
        padding-bottom: 0;
    }

    .contact-content-text h1 {
        font-style: normal;

    }

    .contact-content-text li {
        padding: 0.5rem;

    }

    .For-Customers {
        padding: 0;
        padding-top: 10rem;
    }

    textarea {
        height: 150px;
    }

    form {
        margin: 0 auto;
    }
}

@media screen and (max-width:480px) {

    input[type=text],
    input[type=email],
    textarea {
        width: 350px;
        font-size: 1rem;
    }


    .contact-content-text h1 {
        font-size: 3rem;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .alert {
        width: 60%;
    }

    .alert-message h1 {
        font-size: 3rem;
        margin-bottom: 0;
    }
}