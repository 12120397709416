.home-con {

    background-color: #ffff;
    overflow: hidden;
}

.fst-part {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 2rem;
    margin-bottom: 5rem;
}

.fst-left {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.home-con h1 {
    font-family: 'IvyMode', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 68px;
}

.home-con p,
.home-con li {
    color: #555555;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 120%;
}

.home-logo-fst {
    margin-top: 2rem;
    width: 380px;
    margin-bottom: 3rem;
}

.home-logo-fst img {
    width: 100%;
}

.maneku {
    width: 100%;
}

.maneku p {
    padding-left: 2rem;
    color: gray;
}

.maneku img {
    position: relative;
    max-width: 80%;
}


.fst-right {
    padding-right: 30%;
}

.fst-right h1 {
    margin: 0;
}

.our-service h1 {
    font-style: italic;
}



.our-products h1 {
    margin-bottom: 0;
    margin-top: 0;
}

.our-products p {
    margin-bottom: 5rem;
}

.our-products {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6rem 0 6rem 0;
}

@media screen and (max-width: 1440px) {

    .fst-right {
        padding-right: 20%;
        padding-top: 0;
    }
}

@media screen and (max-width: 1024px) {
    .fst-part {
        grid-template-columns: repeat(1, 1fr);
    }

    .maneku img {
        width: 100%;
    }

    .welcome {
        margin-bottom: 2rem
    }

    .fst-right {
        padding: 5%;
        padding-top: 0;
    }
}

@media screen and (max-width: 600px) {
    .welcome h1 {
        line-height: 60px;
    }

    .home-logo img {
        width: 250px;
    }

    .our-products p {
        margin: 2rem;
    }

    .home-logo-fst {
        width: 240px;
    }
}

@media screen and (max-width: 480px) {
    .home-con h1 {
        font-size: 3rem;
    }

    .welcome h1 {
        line-height: 40px;
    }



    .home-con :is(p, li) {
        font-size: 1rem;
    }
}