.home-nav-con {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: fixed;
    z-index: 9999;
    transition: all 0.2s ease-in-out;
}

.home-nav-con-bg {
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
    background: rgba(255, 255, 255, 0.9);

}

.home-logo {
    padding-top: 0.5rem;
    padding-left: 2rem;
    opacity: 0;
    width: 150px;
}

.home-logo img {
    width: 100%;
}

.show {
    opacity: 1;
}

.home-burger-menu {
    height: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    color: #ffff;
    background: none;
    border-radius: 50%;
}

.burger-bg {
    background: linear-gradient(180deg, #ff3974 0%, #ff6b38 100%);
}


.burger-bar {
    width: 4em;
    height: 0.5em;
    background-color: rgb(0, 183, 255);
    border-radius: 0.5em;
}


/* ------------- sliding menu ------------------ */
.hidden {
    width: 0;
    transition: ease-out 0.3s;
}

.visible {
    width: 100%;
    transition: ease-out 0.3s;

}

@media screen and (max-width: 600px) {
    .home-burger-menu {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .home-logo {
        padding-left: 1rem;
        width: 120px;
        height: 34px;
    }



    .home-burger-menu {
        margin-right: 1rem;
    }
}