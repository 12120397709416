.customers-con {
    padding-top: 10rem;
    text-align: center;

}

.customers-con h1 {
    font-size: 64px;
}


.customers-wrapper {
    padding-top: 5rem;
    padding-bottom: 2rem;
    display: grid;
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: 420px 420px;
    column-gap: 8rem;
    row-gap: 5rem;
}

.customers-info {
    padding-top: 2rem;
    display: flex;
    column-gap: 2rem;
}

.customers-info p,
.customers-info h1 {
    text-align: left;
    margin: 0;
}

.customers-info h1 {
    font-size: 32px;
}

.customers-info p {
    font-size: 20px;
}

.customers-wrapper img {
    width: 100%;

}

.customers-info img {
    width: 90px;

}

@media screen and (max-width: 1200px) {
    .customers-wrapper {
        column-gap: 3rem;
        grid-template-columns: 380px 380px;
    }
}

@media screen and (max-width: 980px) {
    .customers-wrapper {
        grid-template-columns: 320px 320px;
    }

    .customers-info h1 {
        font-size: 30px;
    }

    .customers-info p {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .customers-con h1 {
        font-size: 40px;
    }

    .customers-con p {
        font-size: 18px;
    }

    .customers-wrapper {
        grid-template-columns: 250px 250px;
    }

    .customers-info img {
        width: 90px;

    }
}

@media screen and (max-width: 480px) {
    .customers-wrapper {
        grid-template-columns: 170px 170px;
        gap: 1.7rem;
    }

    .customers-info {
        padding-top: 0.5rem;
        column-gap: 0.5rem;
    }

    .customers-info {
        width: 180px;
        align-items: center;
        justify-content: left;
    }

    .info-img {
        width: 40px;
    }

    .info-img img {
        width: 100%;
    }

    .customers-info h1 {
        font-size: 18px;
    }

    .customers-info p {
        font-size: 13px;
    }


}