.hero-con {
    top: 0;
    position: sticky;
    z-index: -1;
}

.hero {

    width: 100%;
    height: 100vh;

}

.hero-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.overlay {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    color: #ffff;
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
    left: 0;
}

.logo-name {
    margin: 0 auto;
    width: 50%;
}

.logo-name img {
    width: 100%;
}



.see-more p {
    font-family: 'IvyMode', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 0;
}

.see-more {
    position: relative;
    font-size: 40px;
    bottom: -5rem;
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.3;
    }
}

@media screen and (max-width: 600px) {
    .see-more p {
        font-size: 30px;
    }

    .logo-name img {
        padding-top: 2rem;
    }

}

@media screen and (max-width: 480px) {
    .see-more p {
        font-size: 20px;
    }
}