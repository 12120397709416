@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.prducts-list-con {
    margin: 0 auto;
    width: 100%;

}

.slide img {
    margin: 0 auto;
    width: 100%;
}

.slide {
    position: relative;
    transform: scale(0.8);
    transition: transform 300ms;
}

.activeSlide {
    transform: scale(1.0);
    opacity: 1;
}

.arrow {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    font-size: 32px;
}

.arrow svg {
    transition: color 300ms;
}

.arrow svg:hover {
    color: #FF8139;

}

.next {
    right: 0;
    top: 0;
    background: linear-gradient(90deg, #f7628000 0%, #ffffff 100%);
}

.prev {
    background: linear-gradient(-90deg, #f7628000 0%, #ffffff 100%);
    left: 0;
    justify-content: flex-end;
}

.product-overlay {

    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #ff3974 0%, #ff6b38 100%);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.product {
    position: relative;
    width: 320px;
}

.slide .product-overlay h1 {
    font-family: 'IvyMode', sans-serif;
    color: #ffff;
    width: 100%;
    margin: 10% 0 0 10%;
    font-size: 3.5rem;
    font-style: italic;
    font-weight: 300;
    text-align: left;
    line-height: 100%;
}

.activeSlide .product-overlay h1 {
    font-family: 'IvyMode', sans-serif;
    color: #ffff;
    width: 100%;

    font-size: 4rem;
    font-style: italic;
    font-weight: 300;
    text-align: left;
    line-height: 100%;
}

.product-overlay h3 {
    font-family: 'Raleway', sans-serif;
    color: #ffff;
    font-size: 1.5rem;
    font-weight: 400;
    width: 80%;
    text-align: left;
    margin: 0 0 10% 0;
}


.slide:hover .product-overlay {
    height: 100%;
}

@media screen and (max-width: 1440px) {
    .activeSlide .product-overlay h1 {
        font-size: 3rem;
    }

    .slide .product-overlay h1 {
        font-size: 3rem;

    }

    .product-overlay h3 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1200px) {
    .activeSlide .product-overlay h1 {
        font-size: 4rem;
    }

    .slide .product-overlay h3 {
        font-size: 1.2rem;
    }

    .slide .product-overlay h1 {
        font-size: 3.5rem;

    }
}



@media screen and (max-width: 1024px) {}

@media screen and (max-width: 600px) {
    .activeSlide .product-overlay h1 {
        font-size: 4rem;
    }

    .product {
        position: relative;
        width: 240px;
    }

    .product-overlay h3 {
        font-size: 1.5rem;

    }

    .prev,
    .next {
        background: none;
        color: #ffffff;
    }
}