.producers-con {
    text-align: center;
    padding-top: 10rem;
    overflow: hidden;
}

.producers-con h1 {
    font-size: 64px;
}

.producers-con p {
    color: #555555;
}

.producers-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: 360px 360px;
    row-gap: 5rem;
    column-gap: 15%;
}

.producers {

    width: 100%;

}

.producers p {
    font-size: 16px;
}


.producers h1 {
    margin: 0 auto;
    width: fit-content;
    font-size: 32px;
    border-bottom: 1px solid #000000;
}

@media screen and (max-width: 1200px) {
    .producers-wrapper {
        grid-gap: 5rem;
    }


}

@media screen and (max-width: 980px) {
    .producers-wrapper {
        grid-template-columns: 320px 320px;
    }

    .producers h1 {
        font-size: 1.5rem;
    }

    .producers p {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {

    .producers-con h1,
    .producers h1 {
        font-size: 32px;
    }

    .producers-con p,
    .producers p {
        font-size: 16px;
    }

    .producers-wrapper {
        grid-template-columns: 250px 250px;
    }
}

@media screen and (max-width: 500px) {
    .producers-wrapper {
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .pd-info {
        width: 75%;
        margin: 0 auto;
    }

    .producers-wrapper {
        grid-template-columns: 320px;
    }
}