.footer-con {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 2rem; */
    text-align: center;
    color: rgba(0, 0, 0, 0.3);
}

.footer-icons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

}

.footer-icons a {
    color: rgba(0, 0, 0, 0.3);
    font-size: 30px;
}