.products-con {
    text-align: center;
    padding-top: 10rem;
    overflow: hidden;
}

.products-con h1 {
    font-family: 'IvyMode', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 68px;
}

.products-con p {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 120%;
    color: #555555;
}

.products-wrapper {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: 320px 320px 320px;
    grid-gap: 2rem;
}

.products-wrapper div img {
    width: 100%;
}

.products-overlay {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #ff3974 0%, #ff6b38 100%);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.products-overlay h1 {
    text-align: center;
    font-family: 'IvyMode', sans-serif;
    padding: 0;
    color: #ffff;
    width: 80%;
    margin: 10% 0 0 0;
    font-size: 3.5rem;
    font-style: italic;
    font-weight: 300;
    text-align: left;
    line-height: 100%;
}

.products-overlay h3 {
    font-family: 'Raleway', sans-serif;
    color: #ffff;
    font-size: 1.5rem;
    width: 80%;
    text-align: left;
    font-weight: 400;
    margin: 0 0 10% 0;
}

.product:hover .products-overlay {
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .products-wrapper {
        gap: 1rem;
    }

    .products-overlay h1 {
        font-size: 3.5rem;
    }

    .products-overlay h3 {
        font-size: 1.2rem;

    }

}

@media screen and (max-width: 980px) {
    .products-wrapper {
        grid-template-columns: 320px 320px;
    }
}

@media screen and (max-width: 600px) {
    .products-con h1 {
        font-size: 40px;
    }

    .products-con p {
        font-size: 18px;
    }

    .products-wrapper {
        grid-template-columns: 250px 250px;
    }

    .product {
        width: 250px;
    }
}

@media screen and (max-width: 480px) {
    .products-wrapper {
        grid-template-columns: 170px 170px;
    }

    .product {
        width: 170px;
    }

    .products-overlay h1 {
        font-size: 2rem;
        margin: 10% 0 0 5%;
    }

    .products-overlay h3 {
        font-size: 1rem;
    }
}