.rest-con {
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin: 0 auto;
    width: 100%;
    background: rgba(246, 246, 244, 0.5);
    ;
}

.slick-slide {
    transition: transform 500ms ease-in-out;
}